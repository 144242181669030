import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { toast } from 'react-toastify'

const getBorderAndBackground = (type: string) => {
  switch (type) {
    case 'success':
      return {
        border: '1px solid #14C16E',
        background: '#EFFFF7',
      }
    case 'info':
      return {
        border: '1px solid #34ABF4',
        background: '#E6F7FF',
      }
    case 'warning':
      return {
        border: '1px solid #E0AD00',
        background: '#FFFAEB',
      }
    case 'error':
      return {
        border: '1px solid #E35252',
        background: '#FEEBEB',
      }
    default:
      return {
        border: '1px solid #14C16E',
        background: '#EFFFF7',
      }
  }
}

const useCustomToast = () => {
  const show = ({ title = '', subTitle = '', description = '', type = 'success' }) => {
    toast(
      <Box>
        <HStack align="flex-start" spacing="18px">
          {/* <Box>
            <Icon as={getIconByType(type)} w="32px" h="32px" />
          </Box> */}
          <Box flex={1}>
            <Text
              fontWeight="400"
              fontSize="18px"
              lineHeight="23px"
              textTransform="capitalize"
              color="#08010C"
              fontFamily={"Minecraft-Bold"}
            >
              {title}
            </Text>

            <Box flex={1}>
              {subTitle &&
                <Text
                  pt={"5px"}
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#08010C"
                  wordBreak="break-word"
                  fontFamily={"Minecraft"}
                >
                  {subTitle}
                </Text>
              }
              {description && description.includes("https") ?
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#08010C"
                  wordBreak="break-word"
                  onClick={() => {
                    window.open(description)
                  }}
                  fontFamily={"Minecraft"}
                >
                  {description}
                </Text>
                : <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#08010C"
                  wordBreak="break-word"
                  fontFamily={"Minecraft"}
                >
                  {description}
                </Text>
              }
            </Box>
          </Box>
        </HStack>
      </Box>,
      {
        position: toast.POSITION.TOP_RIGHT,
        isLoading: false,
        hideProgressBar: true,
        closeButton: true,
        autoClose: 3000,
        style: {
          borderWidth: 1,
          borderColor: "transparent",
          borderRadius: "12px",
          background: "linear-gradient(#fff 0 0) padding-box,linear-gradient(94.61deg, #48D9A6 -2.1%, #1457FF 51.47%, #FD09C8 108.61%) border-box",
          boxShadow: '0px 0px 14px rgba(186, 186, 186, 0.25)',
        },
      },
    )
  }

  const handleErrorBlockChain = (error: any) => {
    const message = error?.data?.message ?? error?.data?.message ?? (error?.reason ? error?.reason : error?.message)
    // console.log(message, error);

    show({
      title: 'Failed', description: message, type: 'error'
    });
  }

  return {
    show,
    handleErrorBlockChain,
  }
}

export default useCustomToast
