import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react'
import { Fragment, useCallback, useMemo, } from 'react'

import { default as Logo, default as LogoWeb } from '~/assets/logo.png'

import useScrollPosition from '@react-hook/window-scroll'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from '~/hooks/@global'

import ImgBtnBlue from '~/assets/btn_blue.png'
import ImgBtnGreen from '~/assets/btn_green.png'
import ImgBtnYellow from '~/assets/btn_yellow.png'

import CloseIcon from '~/assets/close-menu.svg'
import HamburgerIcon from '~/assets/hamburger-menu.svg'
import useCustomToast from '~/hooks/@global/useCustomToast'


const ButtonCustom = (props: {
  title: string
  href?: string
  icon: string
}) => {

  const { title, href, icon } = props;
  const history = useNavigate();
  const toast = useCustomToast();

  return (
    <VStack
      position={"relative"}
      onClick={() => {
        if (!href) {
          toast.show({
            title: "Warning",
            type: "warning",
            description: "Coming Soon"
          });
          return;
        }
        if (href && href.includes("http")) {
          window.open(href);
        } else {
          history(href)
        }
      }}
      cursor={"pointer"}
    >

      <Image
        src={icon}
        height={{ base: "45px", md: "60px" }}
        w="200px"
        objectFit={"contain"}
      />

      <Center
        position={"absolute"}
        top="30%"
      >
        <Text
          color={`#000`}
          fontWeight={600}
          fontSize={{ base: "13px", md: "16px" }}
          lineHeight={"normal"}
          textAlign={"center"}
        >
          {title}
        </Text>
      </Center>

    </VStack>
  )
}

// const MobileDrawer = ({ onClose, isOpen, }) => {
//   const { colorMode, } = useColorMode();

//   return (
//     <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
//       <DrawerContent mt="55px" w={"190px !important"} background={`${colorMode}.bgPrimary`}>
//         <DrawerBody p="20px 0px 0px" ml={{ base: "10px !important", }}>

//         </DrawerBody>
//       </DrawerContent>
//     </Drawer >
//   )
// }

export const Header = (props: {}) => {
  const history = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const { width } = useWindowSize()
  const { isOpen, onOpen, onClose, } = useDisclosure()
  const { pathname } = useLocation();
  const scrollY = useScrollPosition(60 /*fps*/);

  const checkScrollAtHome = useMemo(() => {
    return scrollY > 176;
    // if (pathname !== "/") {
    //   return true;
    // }
    // return pathname === "/" && scrollY > 176;
  }, [scrollY])

  const renderIcon = useCallback(() => {
    return (
      <HStack>
        <Image
          src={
            isOpen ? HamburgerIcon : CloseIcon
          }
          fontSize="22px"
          onClick={isOpen ? onClose : onOpen}
          color={`${colorMode}.textPrimary`}
        />
      </HStack>
    )
  }, [colorMode, isOpen, onClose, onOpen])

  const renderHeader = useMemo(() => {

    if (width <= 992) {
      return (
        <Box
          py="15px"
          px={{
            base: '5px',
          }}
          h={{
            base: '58px',
          }}
          background={"transparent"}
          // boxShadow={checkScrollAtHome ? "0px 4px 26px 0px #4194FF3D" : "none"}
          zIndex={99}
          w="100%"

          position="fixed"
        >
          <SimpleGrid
            spacing={0}
            columns={3}
            maxW={{ base: "100%", }}
            alignItems={"center"}
          >

            {/* <HStack>
              {renderIcon()}
            </HStack> */}

            <VStack>
              <ButtonCustom
                title='Payment DApp'
                icon={ImgBtnYellow}
                href='https://payment.plantharvest.fun/'
              />
            </VStack>

            <VStack>
              <Image
                src={Logo}
                h="60px"
                objectFit={"contain"}
                onClick={() => {
                  history('/')
                }}
              />
            </VStack>

            <VStack alignItems={"end"}>
              <ButtonCustom
                title='PLAY NOW'
                icon={ImgBtnBlue}
                href='https://t.me/plantharvest_game_bot'
              />
            </VStack>

          </SimpleGrid>
        </Box>
      )
    }
    return (
      <VStack
        position="fixed"
        zIndex={99}
        w="100%"
        background={"transparent"}
      // boxShadow={checkScrollAtHome ? "0px 4px 26px 0px #4194FF3D" : "none"}
      >

        <Flex
          h="100%"
          alignItems={'center'}
          justifyContent={'space-between'}
          maxH="800px"
          w="full"
          maxW={{ base: "100%", lg: "1100px", }}
          padding={{ base: "15px 12px", lg: "12px 32px" }}
        >

          <Image
            src={LogoWeb}
            cursor="pointer"
            onClick={() => {
              history("/")
            }}
            h="100px"
            objectFit={"contain"}
          />

          <ButtonCustom
            title='HOME'
            icon={ImgBtnGreen}
            href='/'
          />

          <ButtonCustom
            title='Payment DApp'
            icon={ImgBtnYellow}
            href='https://payment.plantharvest.fun/'
          />

          <ButtonCustom
            title='PLAY NOW'
            icon={ImgBtnBlue}
            href='https://t.me/plantharvest_game_bot'
          />

        </Flex>

      </VStack >
    )
  }, [checkScrollAtHome, history, renderIcon, width])

  return (
    <Fragment>

      {renderHeader}

      {/* <MobileDrawer
        onClose={onClose}
        isOpen={isOpen}
      /> */}
    </Fragment>
  )
}
