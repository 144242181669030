import { Box, Flex, Image, Text, VStack, useColorMode } from '@chakra-ui/react'
import VertialLayout from '~/@ui/VertialLayout';

const NotFoundView = () => {
  const { colorMode, } = useColorMode();

  return (
    <VertialLayout>
      <VStack
        h={"100vh"}
        w="full"
        justifyContent={"center"}
      >

        <Text
          fontSize={{ base: "42px", xl: "90px" }}
          letterSpacing={"-0.96px"}
          textTransform={"uppercase"}
          className='textPrimary'
          lineHeight={"normal"}
          textAlign={"center"}
        >
          OOPS... PAGE NOT FOUND
        </Text>

      </VStack>
    </VertialLayout>
  )
}

export default NotFoundView
