import { extendTheme, theme } from '@chakra-ui/react'

const breakpoints = {
  base: 0,
  '320': '20em',
  '375': '23.4375em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  '2lg': '64em',
  '3lg': '68.75em',
  xl: '80em',
  xxl: '89em',
  '2xl': '96em',
  '3xl': '120em',
}

const customTheme = extendTheme({
  fonts: {
    body: `'Jost', sans-serif`,
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    light: {
      textPrimary: "#070814",
      subText: "#000",
      textSecondary: "#7C3108",

      primary: '#612323',

      logout: '#FF3D3D',
      footer: '#A5A5A5',

      bgPrimary: '#FFF6A7',
      bgButton: '#FFF6A7',
      bgSecondary: '#fff',
      bgProSider: 'rgba(255, 255, 255, 0.8)', // 1a202c
      bgTrending: '#F1A372',
      bgRed: '#FF4343',
      bgTab: '#FFD467',

      borderColor: "#171923",
      boxShadowPrimary: '0px 0px 10px 0px #FFD467',

    },
    dark: {

      textPrimary: "#fff",
      subText: "#000",
      textSecondary: "#fff",

      primary: '#fff',

      logout: '#FF3D3D',
      footer: '#A5A5A5',

      bgPrimary: '#272D36',
      bgButton: '#FFF6A7',
      bgSecondary: '#1a202c',
      bgProSider: 'rgba(26, 32, 44, 0.8)', // 1a202c
      bgTrending: '#F1A372',
      bgRed: '#FF4343',
      bgTab: '#FFD467',

      borderColor: '#000',
      boxShadowPrimary: '0px 0px 10px 0px #000',
    },
  },
  shadows: {
    ...theme.shadows,
    outline: 'none',
  },
  breakpoints,
})

export default customTheme
