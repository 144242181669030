
const stringDateToUTCDate = (dateStr: string) => {
    const inputDate = new Date(dateStr);
    return new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);
}

const scrollToTop = () => {
    const element = document.getElementById("headerScroll");
    element.scrollIntoView({ behavior: "smooth", block: 'center' })
}

const checkIsFocusUrl = (pathname: string, key: string) => {
    const url = pathname.split("/");

    if (pathname === "/") {
        return true;
    }
    if (url.includes(key)) {
        return true;
    }
    return false;
}


const pipeLongTextUi = (value: string = "", leftCharAmount = 4, rightCharAmount = 4) => {
    if (value.length <= leftCharAmount + rightCharAmount + 3) {
        return value;
    }
    return `${value?.substring(0, leftCharAmount) ?? ''}...
    ${value?.substring(value.length - rightCharAmount) ?? ''}`
}

const formatPrice = (price: string) => {
    if (!price) {
        return '0'
    }

    if (!`${price}`.includes(',')) {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const seperatorList = `${price}`.split(',')
    const intPart = seperatorList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const decimalPart = seperatorList[1] || ''
    return `${intPart},${decimalPart}`
}


export const codeHelpers = {
    stringDateToUTCDate,
    scrollToTop,
    checkIsFocusUrl,
    pipeLongTextUi,
    formatPrice,
}