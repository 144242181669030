import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ChakraProvider, VStack, } from '@chakra-ui/react'
import { Suspense, useMemo, } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ToastContainer, } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { RefreshContextProvider } from './contexts/RefreshContext'
import ModalProvider from './contexts/ModalContext'
import customTheme from './styles/Themes'
import NotFoundView from './views/404'
import { Web3ReactProvider } from '@web3-react/core'
import { evmConnectors } from './@web3-config/evm'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async';
import { HomeView } from './views/HomeView'


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate],
  )
  return children({ history: adaptedHistory, location })
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <HelmetProvider>

          <QueryParamProvider adapter={RouteAdapter as any}>
            <RefreshContextProvider>

              <ModalProvider>

                <Routes>

                  <Route caseSensitive path="/" element={<HomeView />} />

                  <Route path="/*" element={<NotFoundView />} />

                </Routes>

              </ModalProvider>

            </RefreshContextProvider>
          </QueryParamProvider>

        </HelmetProvider>
      </ChakraProvider>
    </QueryClientProvider>
  )
}

const ContainerApp = () => {
  return (
    <Web3ReactProvider connectors={evmConnectors}>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </Web3ReactProvider>
  )
}

export default ContainerApp
