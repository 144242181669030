import React, { useEffect, useState } from 'react'
import { Box, Flex, useColorMode, Image, Icon, VStack } from '@chakra-ui/react'
import useScrollPosition from '@react-hook/window-scroll'
import { useWindowSize } from '~/hooks/@global'
import { codeHelpers } from '~/@helpers/code.helpers'
import { Header } from '~/components/Header'
import BgPrimary from "~/assets/images/home/bg_primary.png"
import BgSecondary from "~/assets/images/home/bg_secondary.png"
import Footer from '~/components/Footer'

const VertialLayout = ({ children }) => {

  const { colorMode, } = useColorMode();
  const { width } = useWindowSize();
  const scrollY = useScrollPosition(60 /*fps*/)

  useEffect(() => {
    codeHelpers.scrollToTop();
  }, [])

  return (
    <Flex overflow="hidden" position="relative" >

      <Box
        overflowY="auto"
        position="relative"
        w="100%"
      >

        <Box id="headerScroll">
          <Header />
        </Box>

        <Box
          pt={{ base: "-90px", }}
          w="full"
        >
          {children}
        </Box>

        {/* <Box h="10vh" /> */}

        {/* <Footer /> */}

      </Box>

      {/* {scrollY > 1300 &&
        <VStack
          zIndex={99}
          cursor={"pointer"}
          position={"fixed"}
          bottom="3%"
          right={{ base: "1%", }}
          borderWidth={1}
          borderStyle={"solid"}
          borderColor={`${colorMode}.primary`}
          background={`${colorMode}.bgPrimary`}
          borderRadius="100px"
          w="30px"
          h="30px"
          onClick={() => {
            codeHelpers.scrollToTop();
          }}
        >
          <Icon
                        mt="5px"
                        as={ArrowTop}
                        color={`${colorMode}.primary`}
                        cursor={{ base: "none", md: "pointer" }}
                        objectFit={"contain"}
                    />
        </VStack>
      } */}

    </Flex>
  )
}

export default VertialLayout
