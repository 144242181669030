import { IEvmChainConfig } from "./dto";

export const EVM_CHAIN_LIST: { [chainId: number]: IEvmChainConfig } = {
  1: {
    chainId: 1,
    rpcUrls: [
      "https://rpc.ankr.com/eth",
      "https://cloudflare-eth.com"
    ],
    chainName: "ETH",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io"],
    iconUrls: ["https://s1.coincarp.com/logo/1/ethereum.png?style=72"],
    logo: "https://s1.coincarp.com/logo/1/ethereum.png?style=72"
  },
  56: {
    chainId: 56,
    rpcUrls: [
      "https://bsc-dataseed1.ninicoin.io"
    ],
    chainName: "BNB Mainnet",
    nativeCurrency: {
      name: "BNB Chain",
      symbol: "BNB",
      decimals: 18
    },
    blockExplorerUrls: ["https://bscscan.com/"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/bsc.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/bsc.png",
  },

  97: {
    chainId: 97,
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18
    },
    rpcUrls: [
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545"
    ],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/bsc.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/bsc.png"
  },
  // Polygon
  137: {
    chainId: 137,
    rpcUrls: [
      "https://polygon-rpc.com"
    ],
    chainName: "Polygon",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://polygonscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/polygon.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/polygon.png"
  },
  80001: {
    chainId: 80001,
    chainName: "Polygon Mumbai",
    nativeCurrency: {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    rpcUrls: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com"
    ],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/polygon.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/polygon.png"
  },

  8453: {
    chainId: 8453,
    chainName: "Base",
    nativeCurrency: {
      "name": "Ether",
      "symbol": "ETH",
      "decimals": 18
    },
    rpcUrls: [
      "https://mainnet.base.org",
      "https://developer-access-mainnet.base.org"
    ],
    blockExplorerUrls: ["https://basescan.org"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/base.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/base.png"
  },
  84531: {
    chainName: "Base Goerli Testnet",
    rpcUrls: [
      "https://goerli.base.org"
    ],
    nativeCurrency: {
      name: "Goerli Ether",
      symbol: "ETH",
      decimals: 18
    },
    chainId: 84531,
    blockExplorerUrls: ["https://goerli.basescan.org"],
    iconUrls: ["https://d111ej16yd04xs.cloudfront.net/upload/mvs/res/d42a9654-0db1-496f-96cf-87f35b1d130f.png"],
    logo: "https://d111ej16yd04xs.cloudfront.net/upload/mvs/res/d42a9654-0db1-496f-96cf-87f35b1d130f.png",

  },
  10: {
    chainName: "OP",
    rpcUrls: [
      "https://mainnet.optimism.io/"
    ],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    chainId: 10,
    blockExplorerUrls: [
      "https://optimistic.etherscan.io"
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/optimism.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/optimism.png"
  },
  420: {
    chainName: "OP Goerli Testnet",
    rpcUrls: [
      "https://goerli.optimism.io/"
    ],

    nativeCurrency: {
      name: "Goerli Ether",
      symbol: "ETH",
      decimals: 18
    },
    chainId: 420,
    blockExplorerUrls: [
      "https://goerli-optimism.etherscan.io"
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/optimism.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/optimism.png"
  },
  7000: {
    chainId: 7000,
    chainName: 'ZetaChain Mainnet',
    nativeCurrency: { name: 'Zeta', symbol: 'ZETA', decimals: 18 },
    rpcUrls: ['https://api.mainnet.zetachain.com/evm'],
    blockExplorerUrls: ['https://explorer.mainnet.zetachain.com'],
    iconUrls: [],
    logo: ''
  },
  7001: {
    chainId: 7001,
    chainName: 'ZetaChain Testnet',
    nativeCurrency: { name: 'Zeta', symbol: 'aZETA', decimals: 18 },
    rpcUrls: ['https://rpc.ankr.com/zetachain_evm_athens_testnet'],
    blockExplorerUrls: [
      'https://athens3.explorer.zetachain.com',
      'https://zetachain-athens-3.blockscout.com'
    ],
    iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png"],
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png"
  },

  280: {
    chainId: 280,
    chainName: 'zkSync Era Testnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://testnet.era.zksync.dev'],
    blockExplorerUrls: ['https://goerli.explorer.zksync.io'],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/zksync.png"],
    logo: 'https://dd.dexscreener.com/ds-data/chains/zksync.png'
  },
  324: {
    chainId: 324,
    chainName: 'zkSync Era',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.era.zksync.io'],
    blockExplorerUrls: ['https://explorer.zksync.io'],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/zksync.png"],
    logo: 'https://dd.dexscreener.com/ds-data/chains/zksync.png'
  },
  42161: {
    chainId: 42161,
    chainName: 'Arbitrum One',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [
      "https://arbitrum.llamarpc.com",
      'https://arbitrum-one.publicnode.com',
      'https://arb1.arbitrum.io/rpc',
    ],
    blockExplorerUrls: [
      'https://arbiscan.io',
      'https://explorer.arbitrum.io',
      'https://arbitrum.dex.guru'
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/arbitrum.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/arbitrum.png"
  },
  421613: {
    chainId: 421613,
    chainName: 'Arbitrum Goerli',
    nativeCurrency: { name: 'Arbitrum Goerli Ether', symbol: 'AGOR', decimals: 18 },
    rpcUrls: [
      'https://goerli-rollup.arbitrum.io/rpc',
      'https://arbitrum-goerli.publicnode.com',
    ],
    blockExplorerUrls: ['https://goerli.arbiscan.io'],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/arbitrum.png"],
    logo: 'https://dd.dexscreener.com/ds-data/chains/arbitrum.png'
  },
  534351: {
    chainId: 534351,
    chainName: 'Scroll Sepolia Testnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [
      'https://sepolia-rpc.scroll.io',
      'https://rpc.ankr.com/scroll_sepolia_testnet',
      'https://scroll-sepolia.chainstacklabs.com',
      'https://scroll-testnet-public.unifra.io'
    ],
    blockExplorerUrls: [
      'https://sepolia.scrollscan.dev',
      'https://sepolia-blockscout.scroll.io'
    ],
    iconUrls: ['https://dd.dexscreener.com/ds-data/chains/scroll.png'],
    logo: 'https://dd.dexscreener.com/ds-data/chains/scroll.png'
  },
  534352: {
    chainId: 534352,
    chainName: 'Scroll',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [
      'https://scroll-mainnet.chainstacklabs.com',
      'https://rpc.scroll.io',
      'https://rpc-scroll.icecreamswap.com',
      'https://rpc.ankr.com/scroll',
    ],
    blockExplorerUrls: ['https://scrollscan.com', 'https://blockscout.scroll.io'],
    iconUrls: ['https://dd.dexscreener.com/ds-data/chains/scroll.png'],
    logo: 'https://dd.dexscreener.com/ds-data/chains/scroll.png'
  },
  204: {
    chainId: 204,
    chainName: 'opBNB',
    nativeCurrency: {
      name: "BNB Chain",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: [
      'https://opbnb-mainnet-rpc.bnbchain.org',
    ],
    blockExplorerUrls: ['https://opbnbscan.com'],
    iconUrls: ["https://s1.coincarp.com/logo/1/binance-coin.png?style=36"],
    logo: "https://s1.coincarp.com/logo/1/binance-coin.png?style=36",
  },
  59144: {
    chainId: 59144,
    chainName: 'Linea',
    nativeCurrency: {
      name: "Linea Ether",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: [
      'https://rpc.linea.build',
    ],
    blockExplorerUrls: ['https://lineascan.build'],
    iconUrls: ["https://s1.coincarp.com/logo/1/linea.png?style=72"],
    logo: "https://s1.coincarp.com/logo/1/linea.png?style=72",
  },

}
