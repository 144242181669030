
import { VStack, useColorMode, } from '@chakra-ui/react'
import { Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '~/hooks/@global';

import { ThreeDots } from "react-loader-spinner"
import React from 'react';

const ScreensHomeLazy = React.lazy(() =>
  import("./HomeViewLazy")
    .then(({ HomeViewLazy }) => ({ default: HomeViewLazy })),
);

export const HomeView = () => {
  const { colorMode } = useColorMode();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const LoadingView = () => {
    return (
      <VStack
        h={"100vh"}
        w="full"
        justifyContent={"center"}
      >

        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#000"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />

      </VStack>
    )
  }

  return (
    <Suspense fallback={<LoadingView />}>
      <ScreensHomeLazy />
    </Suspense>
  )
}
