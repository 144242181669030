import { CHAIN_CODE } from "../chain-code";
import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "DEVELOPMENT",
  CONNECTORS: {
    ROOT: {
      baseUrl: "http://localhost:5002"
    }
  },
  SSO: {
    loginUrl: "https://sso.rex-labs.com/login".trim(),
    clientId: "mint_rex_prod".trim(),
  },
  applyChainIds: [CHAIN_CODE.ARBITRUM_MAINNET, CHAIN_CODE.BASE_MAINNET, CHAIN_CODE.OP_MAINNET, CHAIN_CODE.SCROLL_MAINNET,],
  customSMC: {
    // BASE
    [CHAIN_CODE.BASE_MAINNET]: {
      REX_FEE_FACTORY: "".trim()
    },

    // OP
    [CHAIN_CODE.OP_MAINNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.ZKSYNC_ERA_MAINNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.ZETACHAIN_TESTNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.ARBITRUM_MAINNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.SCROLL_MAINNET]: {
      REX_FEE_FACTORY: "".trim()
    },
  }
}

export default config;
