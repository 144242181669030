import { CHAIN_CODE } from "../chain-code";
import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "QA",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://api.mint-rex.dogeden.app"
    }
  },
  SSO: {
    loginUrl: "https://sso.rex-labs.com/login".trim(),
    clientId: "mint_rex_prod".trim(),
  },
  applyChainIds: [CHAIN_CODE.ARBITRUM_GOERLI_TESTNET, CHAIN_CODE.OP_GOERLI_TESTNET, CHAIN_CODE.BASE_GOERLI_TESTNET, CHAIN_CODE.ZETACHAIN_TESTNET],
  customSMC: {
    // OP
    [CHAIN_CODE.OP_GOERLI_TESTNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    // BASE
    [CHAIN_CODE.BASE_GOERLI_TESTNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    // Zeta
    [CHAIN_CODE.ZETACHAIN_TESTNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.ARBITRUM_GOERLI_TESTNET]: {
      REX_FEE_FACTORY: "".trim()
    }
  }
}

export default config;
