import { CHAIN_CODE } from "../chain-code";
import { IEnvConfig } from "../dto";


const config: IEnvConfig = {
  name: "PRODUCTION",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://api.mint-rex.dogeden.app"
    },
  },
  SSO: {
    loginUrl: "https://sso.rex-labs.com/login".trim(),
    clientId: "mint_rex_prod".trim(),
  },
  applyChainIds: [
    CHAIN_CODE.SCROLL_MAINNET,
    CHAIN_CODE.BSC_MAINNET,
    CHAIN_CODE.LINEA,
    CHAIN_CODE.ETH,
    CHAIN_CODE.BASE_MAINNET,
    CHAIN_CODE.ARBITRUM_MAINNET,
    CHAIN_CODE.POLYGON_MAINNET,
    CHAIN_CODE.OP_MAINNET,
  ],
  customSMC: {
    // BASE
    [CHAIN_CODE.BASE_MAINNET]: {
      REX_FEE_FACTORY: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim()
    },

    // OP
    [CHAIN_CODE.OP_MAINNET]: {
      REX_FEE_FACTORY: "0x19fb5a5ee3d93E8a5BdC330AC8D04B496C91792e".trim()
    },
    [CHAIN_CODE.ZKSYNC_ERA_MAINNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.ZETACHAIN_TESTNET]: {
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.ARBITRUM_MAINNET]: {
      REX_FEE_FACTORY: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim()
    },
    [CHAIN_CODE.OPBNB]: {
      // REX_FEE_FACTORY: "0xb9EC4C13172636b5F1f80e667F9ed437F5f5D4FD".trim()
      REX_FEE_FACTORY: "".trim()
    },
    [CHAIN_CODE.SCROLL_MAINNET]: {
      // REX_FEE_FACTORY: "0x75EaC825301522b8420748e7B2069Cf05f0cb5ce".trim()
      REX_FEE_FACTORY: "0x518E5e35a80cfD2F01cA3f9693a0fD6c530EbdeA".trim()
    },
    [CHAIN_CODE.BSC_MAINNET]: {
      // REX_FEE_FACTORY: "0xb9EC4C13172636b5F1f80e667F9ed437F5f5D4FD".trim()
      REX_FEE_FACTORY: "0xCCb18273696CA4D8623594E0AeF8B90515CC5F3a".trim()
    },
    [CHAIN_CODE.LINEA]: {
      REX_FEE_FACTORY: "0xb9EC4C13172636b5F1f80e667F9ed437F5f5D4FD".trim()
    },
    [CHAIN_CODE.ETH]: {
      REX_FEE_FACTORY: "0x19fb5a5ee3d93E8a5BdC330AC8D04B496C91792e".trim()
    },
    [CHAIN_CODE.POLYGON_MAINNET]: {
      REX_FEE_FACTORY: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim()
    },
  }
}

export default config;
